.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
	gap: var(--hdsplus-spacing-08);

	@media (--medium-up) {
		gap: var(--hdsplus-spacing-10);
	}

	@media (--large) {
		gap: var(--hdsplus-spacing-11);
	}
}

.header {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / span 9;
	}

	@media (--large) {
		grid-column: 1 / span 6;
	}
}

.items {
	/* Each layout uses fixed row heights
	to properly achieve mobile designs */
	--small-row: 284px;
	--large-row: 480px;

	grid-column: 1 / -1;
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	gap: var(--hdsplus-spacing-08);

	@nest .optionA & {
		grid-template-rows: repeat(2, var(--large-row));
		grid-template-areas:
			'a'
			'b';

		@media (--large) {
			grid-template-rows: var(--large-row);
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas: 'a b';
		}
	}

	@nest .optionB & {
		@media (--small) {
			grid-template-rows: repeat(3, var(--large-row));
			grid-template-areas:
				'a'
				'b'
				'c';
		}

		@media (--medium) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: var(--small-row) var(--small-row) 393px;
			grid-template-areas:
				'a b'
				'a b'
				'c c';
		}

		@media (--large) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: var(--small-row) var(--small-row);
			grid-template-areas:
				'a b c'
				'a b c';
		}
	}

	@nest .optionC & {
		grid-template-rows: var(--large-row) var(--small-row) var(--small-row);

		@media (--small) {
			grid-template-areas:
				'a'
				'b'
				'c';
		}

		@media (--medium) {
			grid-template-rows: repeat(3, var(--small-row));
			grid-template-areas:
				'a a'
				'a a'
				'b c';
		}

		@media (--large) {
			grid-template-rows: repeat(2, var(--small-row));
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:
				'a a b'
				'a a c';
		}
	}

	@nest .optionD & {
		@media (--small) {
			grid-template-rows:
				var(--small-row) var(--small-row) var(--large-row) var(--small-row)
				var(--small-row);
			grid-template-areas:
				'b'
				'c'
				'a'
				'd'
				'e';
		}

		@media (--medium) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(3, var(--small-row));
			grid-template-areas:
				'b c'
				'a d'
				'a e';
		}

		@media (--large) {
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: repeat(2, var(--small-row));
			grid-template-areas:
				'b a d'
				'c a e';
		}
	}

	@nest .optionE & {
		@media (--small) {
			grid-template-rows: var(--large-row) var(--large-row) var(--small-row) var(
					--small-row
				);
			grid-template-areas:
				'a'
				'b'
				'c'
				'd';
		}

		@media (--medium) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(3, var(--small-row));
			grid-template-areas:
				'a b'
				'a b'
				'c d';
		}

		@media (--large) {
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:
				'a b c'
				'a b d';
		}
	}

	/* Retrofit subgrid to support flush top alignment of text blocks in optionA and optionB */
	@supports (grid-template-columns: subgrid) {
		@nest .optionA.topAlignText & {
			@media (--large) {
				height: var(--large-row);
				grid-template-rows: minmax(0, 1fr) max-content;

				& > .item {
					display: contents;

					& > div {
						grid-row: 1 / span 2;
						grid-template-rows: subgrid;
						grid-template-areas: "image" "content";
						gap: 0;

						& > div:first-child {
							grid-area: image;
						}

						& > div:last-child {
							grid-area: content;
						}
					}
				}
			}
		}
		
		@nest .optionB.topAlignText & {
			@media (--medium) {
				height: calc(var(--small-row) * 2 + var(--hdsplus-spacing-08) * 2 + 393px);
				grid-template-rows: minmax(0, 1fr) max-content 393px;

				& > .item:nth-child(1),
				& > .item:nth-child(2) {
					display: contents;

					& > div {
						grid-row: 1 / span 2;
						grid-template-rows: subgrid;
						grid-template-areas: "image" "content";
						gap: 0;

						& > div:first-child {
							grid-area: image;
						}

						& > div:last-child {
							grid-area: content;
						}
					}
				}
			}
			@media (--large) {
				height: calc(var(--small-row) * 2 + var(--hdsplus-spacing-08));
				grid-template-rows: minmax(0, 1fr) max-content;
				gap: 0 var(--hdsplus-spacing-08);

				& > .item {
					display: contents;

					& > div {
						grid-row: 1 / span 2;
						grid-template-rows: subgrid;
						grid-template-areas: "image" "content";

						& > div:first-child {
							grid-area: image;
						}

						& > div:last-child {
							grid-area: content;
						}
					}
				}
			}
		}
	}
}

.item {
	&:nth-child(1) {
		grid-area: a;
	}

	&:nth-child(2) {
		grid-area: b;
	}

	&:nth-child(3) {
		grid-area: c;
	}

	&:nth-child(4) {
		grid-area: d;
	}

	&:nth-child(5) {
		grid-area: e;
	}
}